import React from "react"
import Menu from "../components/menu"
import HeaderImage from "./../../static/img/header-image.png"
import HeaderGeneral from "./../../static/img/header-general.png"
import HeaderInstagram from "./../../static/img/header-instagram.png"
import HeaderSoundcloud from "./../../static/img/header-soundcloud.png"
import HeaderTiktok from "./../../static/img/header-tiktok.png"
import { Button } from "antd"
import { Link, navigate } from "gatsby"

const Main = props => {
  const scrollToDiv = id => {
    const el = document.getElementById(id)
    window.scroll({ top: el.offsetTop, behavior: "smooth" })
  }
  const getTextBtn = (view) => {
    if(view === "home"){
      return "Start free trial"
    }
    if(view === "terms" || view === "privacy"){
      return;
    }
    return "Get Started"
  }
  return (
    <div>
      <Menu />
      <div className="flex justify-center items-center pt-20 px-10">
        <div className="hidden lg:inline">
          {props.viewName === "home" && (
            <div style={{ width: "600px" }}>
              <img
                style={{
                  width: "600px",
                  position: "absolute",
                  marginTop: "-180px",
                  marginLeft: "-25px",
                }}
                src={HeaderGeneral}
                className="header-section-image z-10"
                alt="mobile"
              />
            </div>
          )}
        </div>
        <div className={`header-section-content color-home`}>
          <div className={`header-section-title color-home`}>
            {props.viewName === "tiktok" && (
              <p>Accelerate your growth on <span className="blink-text">TikTok</span></p>
            )}
            {props.viewName !== "tiktok" && (
              props.title
            )}
          </div>
          {props.subTitle && (
            <div className="header-section-subtitle">{props.subTitle}</div>
          )}
          <p className="header-section-description">{props.description}</p>
          <p className="header-section-description">{props.description2}</p>
          <p>
            <Button
              type="primary"
              shape="round"
              style={{ height: "40px" }}
              className={`background-color-header-button-${props.viewName} background-color-home home-button color-${props.viewName}`}
              onClick={() => {
                if(props.viewName === "home") {
                  return navigate("/contact")
                }
                return document.getElementById("pricing")
                  ? scrollToDiv("pricing")
                  : navigate("/")
              }}
              onKeyDown={() => {
                if(props.viewName === "home") {
                  return window.location.href = "https://app.boostik.io"
                }
                return document.getElementById("pricing")
                  ? scrollToDiv("pricing")
                  : navigate("/")
              }}
            >
              {getTextBtn(props.viewName)}

            </Button>
          </p>
        </div>
        <div className="hidden lg:inline">
          {props.viewName === "instagram" && (
            <div style={{ width: "450px" }}>
              <img
                style={{
                  width: "450px",
                  position: "absolute",
                  marginTop: "-180px",
                }}
                src={HeaderInstagram}
                className="header-section-image z-10"
                alt="mobile"
              />
            </div>
          )}
          {props.viewName === "soundcloud" && (
            <div style={{ width: "500px" }}>
              <img
                style={{
                  width: "600px",
                  position: "absolute",
                  marginTop: "-180px",
                }}
                src={HeaderSoundcloud}
                className="header-section-image z-10"
                alt="mobile"
              />
            </div>
          )}
          {props.viewName === "tiktok" && (
            <div style={{ width: "450px" }}>
              <img
                style={{
                  width: "450px",
                  position: "absolute",
                  marginTop: "-160px",
                  marginLeft: "25px",
                }}
                src={HeaderTiktok}
                className="header-section-image z-10"
                alt="mobile"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Main
